import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { gsap } from 'gsap';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
  Avatar
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Home as HomeIcon,
  TrendingUp,
  CenterFocusStrong,
  EventNote,
  School as SchoolIcon,
  Campaign as CampaignIcon,
  PieChart as PieChartIcon,
  Psychology as PsychologyIcon,
  Feedback,
  Assignment,
  Podcasts as PodcastsIcon,
  Videocam as WebinarIcon,
  Verified,
  PersonAdd,
  Info as InfoIcon,
  Memory as MemoryIcon,
  Article as ArticleIcon,
} from '@mui/icons-material';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import GroupsIcon from '@mui/icons-material/Groups';


function Sidebar({ isMobile, isOpen, onClose }) {
  const [centerMarketing, setCenterMarketingOpen] = useState(false);
  const [centerClientData, setCenterClientDataOpen] = useState(false);
  const [networkInformation, setNetworkInformationOpen] = useState(false);
  const [accreditationOpen, setAccreditationOpen] = useState(false);
  const [entrepreneurAcademyOpen, setEntrepreneurAcademyOpen] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    gsap.to('.badge-dot', {
      scale: 2,
      opacity: .25,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
      duration: 1,
    });
  }, []);

  const handleItemClick = (callback) => {
    callback();
    if (isMobile) {
      onClose();
    }
  };

  console.log('Sidebar rendered, isOpen:', isOpen, 'isMobile:', isMobile);

  const mainListItems = (
    <React.Fragment>
      {user.partner ? (
        <>
          <Link to={'/'}>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <EventNote />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Impact Report" />
            </ListItemButton>
          </Link>
        </>
      ) : (
        <>
          <Link to={'/'}>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Home" />
            </ListItemButton>
          </Link>

          <Link to={'/strategic-plan'}>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Strategic Plan" />
              {/* <Box className="badge-dot" sx={{ width: 5, height: 5, borderRadius: '50%', backgroundColor: '#1876d2', ml: 1 }} /> */}
            </ListItemButton>
          </Link>

          {/* Add AI Tools link with Pulsing Badge */}
          <Link to='/ai-tools'>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <MemoryIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="AI Tools" />
              <Box className="badge-dot" sx={{ width: 5, height: 5, borderRadius: '50%', backgroundColor: '#1876d2', ml: 1 }} />
            </ListItemButton>
          </Link>

          {/* Add Success Stories link with Pulsing Badge */}
          <Link to='/success-stories'>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Success Stories" />
              <Box className="badge-dot" sx={{ width: 5, height: 5, borderRadius: '50%', backgroundColor: '#1876d2', ml: 1 }} />
            </ListItemButton>
          </Link>

          {/* Network Directory moved to main level */}
          <Link to={'network-directory'}>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <ContactPageIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Network Directory" />
            </ListItemButton>
          </Link>

          <ListItemButton onClick={() => handleItemClick(() => setCenterMarketingOpen(!centerMarketing))}>
            <ListItemIcon>
              <TrendingUp />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Center Marketing" />
            {centerMarketing ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={centerMarketing} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'brand-assets'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <CampaignIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Brand Assets" />
                </ListItemButton>
              </Link>
              <Link to={'/marketing-plan'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PsychologyIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Marketing Plan" />
                </ListItemButton>
              </Link>
              <Link to={'/market-segmentation'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PieChartIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Market Segmentation" />
                </ListItemButton>
              </Link>
              <Link to={'ai'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PsychologyIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="AI" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => handleItemClick(() => setCenterClientDataOpen(!centerClientData))}>
            <ListItemIcon>
              <CenterFocusStrong />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Center Client Data" />
            {centerClientData ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={centerClientData} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'client-satisfaction'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <Feedback />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Client Satisfaction Survey" />
                </ListItemButton>
              </Link>
              <Link to={'needs-assessment'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <Assignment />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Client Needs Assessment" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => handleItemClick(() => setEntrepreneurAcademyOpen(!entrepreneurAcademyOpen))}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Entrepreneur Academy" />
            {entrepreneurAcademyOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={entrepreneurAcademyOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'/podcasts'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PodcastsIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Podcasts" />
                </ListItemButton>
              </Link>
              <Link to={'/webinars'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <WebinarIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Webinars" />
                </ListItemButton>
              </Link>
              <Link to={'/ea-modules'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Modules" />
                </ListItemButton>
              </Link>
              <Link to={'/counselor-certification'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <Verified />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Counselor Certification" />
                </ListItemButton>
              </Link>
              <Link to={'/onboarding'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PersonAdd />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Onboarding" />
                </ListItemButton>
              </Link>
              <Link to={'/ea-report'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Reports" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => handleItemClick(() => setNetworkInformationOpen(!networkInformation))}>
            <ListItemIcon>
              <EventNote />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Network Information" />
            {networkInformation ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={networkInformation} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'operations-manual'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Operations Manual" />
                </ListItemButton>
              </Link>
              {user.center === 'lead' && (
                <Link to={'impact-reports'}>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                    <ListItemIcon>
                      <EventNote />
                    </ListItemIcon>
                    <ListItemText sx={{ color: '#000' }} primary="Quarterly Impact Report" />
                  </ListItemButton>
                </Link>
              )}

              <ListItemButton onClick={() => handleItemClick(() => setAccreditationOpen(!accreditationOpen))}>
                <ListItemIcon>
                  <Verified />
                </ListItemIcon>
                <ListItemText sx={{ color: '#000' }} primary="Accreditation" />
                {accreditationOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={accreditationOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <Link to={'/accreditation/accreditation-report'}>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                      <ListItemIcon>
                        <EventNote />
                      </ListItemIcon>
                      <ListItemText sx={{ color: '#000' }} primary="Accreditation Report" />
                    </ListItemButton>
                  </Link>
                  <Link to={'/accreditation/self-study'}>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                      <ListItemIcon>
                        <EventNote />
                      </ListItemIcon>
                      <ListItemText sx={{ color: '#000' }} primary="Self Study" />
                    </ListItemButton>
                  </Link>
                  <Link to={'/accreditation/standards'}>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                      <ListItemIcon>
                        <EventNote />
                      </ListItemIcon>
                      <ListItemText sx={{ color: '#000' }} primary="Standards" />
                    </ListItemButton>
                  </Link>
                  <Link to={'/accreditation/requests'}>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                      <ListItemIcon>
                        <Assignment />
                      </ListItemIcon>
                      <ListItemText sx={{ color: '#000' }} primary="Additional Requested Information" />
                    </ListItemButton>
                  </Link>
                </List>
              </Collapse>
            </List>
          </Collapse>
        </>
      )}
    </React.Fragment>
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
        <Link to="/user-profile">
          <Avatar alt={user.name} src={user.picture} sx={{ mr: 2 }} />
        </Link>
        <Typography variant="h6" sx={{ color: '#000' }}>
          {user.name}
        </Typography>
      </Box>
      <List>
        {mainListItems}
      </List>
    </Box>
  );
}

export default Sidebar;
