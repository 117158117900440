import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import { MarketSegmentationPage } from "./pages/market-segmentation-page";
import { MarketSegmentationDetailPage } from "./pages/market-segmentation-detail-page";
import { NetworkDirectoryPage } from "./pages/network-directory-page";
import { ReceiptSubmissionPage } from "./pages/receipt-submission-page";
import { StrategicTeamsTeamPage } from "./pages/strategicTeamsTeamPage";
import { StrategicTeamsPage } from "./pages/strategicTeamsPage";
import { ReportsPage } from "./pages/reports-page";
import { ReportsPageLW } from "./pages/reports-page-lw";
import { ReportsPageUserDetails } from "./pages/reports-page-user-details";
import { AI } from "./pages/AI";
import { CourseReview } from "./pages/course-review";
import { GenerateVideo } from "./pages/GenerateVideo";
import EditableDocument from "./components/dashboard/EditableDocument";
import BrandAssets from "./components/BrandAssets";
import Podcasts from "./components/Podcasts";
import { MarketSegmentationCenterDetailPage } from "./pages/market-segmentation-center-detail";
import { MarketSegmentationCodeDetails } from "./pages/market-segmentation-code-details";
import { MarketSegmentationCountyCodeDetails } from "./components/MarketSegmentationCountyCodeDetails";
import { MarketSegmentationCountyDetailPage } from "./pages/market-segmentation-county-detail";
import { Knowledgebase } from "./pages/knowledgebase";
import { Guide } from "./pages/guide";
import { Sop } from "./pages/sop";
import { MarketSegmentationResearch } from "./pages/market-segmentation-research";
import DirectorsMeeting from "./components/DirectorsMeeting";
import CounselorCertification from "./components/dashboard/CounselorCertification";
import ImpactOverview from "./components/dashboard/ImpactOverview";
import SixYearImpact from "./components/dashboard/SixYearImpact";
import { OperationsManual } from "./pages/operations-manual";
import { AccreditationStandards } from "./pages/accreditation-standards";
import { SelfStudy } from "./pages/self-study";
import SuccessStories from "./components/SuccessStories";
import Webinars from "./components/dashboard/Webinars";
import Modules from "./components/dashboard/Modules";
import Satisfaction from "./components/Satisfaction";
import { UpdateUser } from "./pages/UpdateUser";
import { SbdcDayPage } from "./pages/sbdc-day-page";
import { SatsisfactionSurveyPage } from "./pages/SatisfactionSurveyPage";
import { NeedsAssessment } from "./pages/needs-assessment";
import { Benchmarking } from "./pages/Benchmarking";
import { AccreditationRequests } from "./pages/accreditation-requests";
import Onboarding from "./components/dashboard/Onboarding";
import { PartnerReports } from "./pages/PartnerReports";
import PartnerPie from "./components/PartnerPie";
import { PartnerReportsEA } from "./pages/PartnerReportsEA";
import { EAOverview } from "./pages/EAOverview";
import { AgendaPage } from "./pages/AgendaPage";
import StrategicTeamDetail from "./pages/strategicTeamDetail";
import EntrepreneurAcademy from "./components/EntrepreneurAcademy";
import { AItoolsPage } from "./pages/aiToolsPage";
import { SuccessStoriesPage } from "./pages/success-stories-page";
import AccreditationReport from "./pages/accreditation-report";

export const MainRoute = () => {
    const { isLoading, user } = useAuth0();

    if (isLoading) {
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        );
    }

    if (user && user.partner) {
        return (
            <Routes>
                <Route path="/" element={<AuthenticationGuard component={PartnerReports} />} />
                <Route path="/user-profile" element={<AuthenticationGuard component={UpdateUser} />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        );
    }


    if (user && user.center == 'accreditation') {
        return (
            <Routes>
                <Route path="/" element={<AuthenticationGuard component={AccreditationReport} />} />
                <Route path="/user-profile" element={<AuthenticationGuard component={UpdateUser} />} />
                <Route path="/strategic-plan/detail/:id" element={<AuthenticationGuard  component={StrategicTeamDetail} />}/>
                <Route path="/accreditation/self-study" element={<AuthenticationGuard component={SelfStudy} />} />
                <Route path="/accreditation/accreditation-report" element={<AuthenticationGuard component={AccreditationReport}/>} />
                <Route path="/accreditation/requests" element={<AuthenticationGuard component={AccreditationRequests} />} />
                <Route path="/accreditation/standards" element={<AuthenticationGuard component={AccreditationStandards} />} />
                <Route path="/strategic-plan" element={<AuthenticationGuard component={StrategicTeamsPage} />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        );
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/accreditation/accreditation-report" element={<AuthenticationGuard component={AccreditationReport}/>} />
                <Route path="/ai-tools" element={<AuthenticationGuard component={AItoolsPage}/>} />
                <Route path="/success-stories" element={<AuthenticationGuard component={SuccessStoriesPage}/>} />
                <Route path="/strategic-plan/detail/:id" element={<AuthenticationGuard  component={StrategicTeamDetail} />}/>
                <Route path="/profile" element={<AuthenticationGuard component={ProfilePage} />} />
                <Route path="/conference-agenda" element={<AuthenticationGuard component={AgendaPage} />} />
                <Route path="/ea-overview" element={<AuthenticationGuard component={EAOverview} />} />
                <Route path="/ea-report" element={<AuthenticationGuard component={EntrepreneurAcademy} />} />
                <Route path="/market-segmentation" element={<AuthenticationGuard component={MarketSegmentationPage} />} />
                <Route path="/market-segmentation/center-detail" element={<AuthenticationGuard component={MarketSegmentationCenterDetailPage} />} />
                <Route path="/market-segmentation/county-detail" element={<AuthenticationGuard component={MarketSegmentationCountyDetailPage} />} />
                <Route path="/market-segmentation/county-detail-code/:codeId" element={<AuthenticationGuard component={MarketSegmentationCountyCodeDetails} />} />
                <Route path="/market-segmentation/center-detail-code/:codeId" element={<AuthenticationGuard component={MarketSegmentationCodeDetails} />} />
                <Route path="/market-segmentation/detail" element={<AuthenticationGuard component={MarketSegmentationDetailPage} />} />
                <Route path="/market-segmentation/research" element={<AuthenticationGuard component={MarketSegmentationResearch} />} />
                <Route path="/marketing-plan" element={<AuthenticationGuard component={EditableDocument} />} />
                <Route path="/counselor-certification" element={<AuthenticationGuard component={CounselorCertification} />} />
                <Route path="/onboarding" element={<AuthenticationGuard component={Onboarding} />} />
                <Route path="/webinars" element={<AuthenticationGuard component={Webinars} />} />
                <Route path="/ea-modules" element={<AuthenticationGuard component={Modules} />} />
                <Route path="/year-to-date-impact" element={<AuthenticationGuard component={ImpactOverview} />} />
                <Route path="/six-year-impact" element={<AuthenticationGuard component={SixYearImpact} />} />
                <Route path="/brand-assets" element={<AuthenticationGuard component={BrandAssets} />} />
                <Route path="/client-satisfaction" element={<AuthenticationGuard component={Satisfaction} />} />
                <Route path="/client-satisfaction/questions" element={<AuthenticationGuard component={SatsisfactionSurveyPage} />} />
                <Route path="/podcasts" element={<AuthenticationGuard component={Podcasts} />} />
                <Route path="/success-stories" element={<AuthenticationGuard component={SuccessStories} />} />
                <Route path="/directors-meeting" element={<AuthenticationGuard component={DirectorsMeeting} />} />
                <Route path="/reports" element={<AuthenticationGuard component={ReportsPage} />} />
                <Route path="/benchmarking" element={<AuthenticationGuard component={Benchmarking} />} />
                <Route path="/ea-reports" element={<AuthenticationGuard component={ReportsPageLW} />} />
                <Route path="/ea-reports/user-details/:userId" element={<AuthenticationGuard component={ReportsPageUserDetails} />} />
                <Route path="/strategic-plan" element={<AuthenticationGuard component={StrategicTeamsPage} />} />
                <Route path="/network-directory" element={<AuthenticationGuard component={NetworkDirectoryPage} />} />
                <Route path="/operations-manual" element={<AuthenticationGuard component={OperationsManual} />} />
                <Route path="/needs-assessment" element={<AuthenticationGuard component={NeedsAssessment} />} />
                <Route path="/accreditation/self-study" element={<AuthenticationGuard component={SelfStudy} />} />
                <Route path="/sbdc-day" element={<AuthenticationGuard component={SbdcDayPage} />} />
                <Route path="/accreditation/requests" element={<AuthenticationGuard component={AccreditationRequests} />} />
                <Route path="/accreditation/standards" element={<AuthenticationGuard component={AccreditationStandards} />} />
                <Route path="/public" element={<PublicPage />} />
                <Route path="/protected" element={<AuthenticationGuard component={ProtectedPage} />} />
                <Route path="/admin" element={<AuthenticationGuard component={AdminPage} />} />
                <Route path="/knowledgebase" element={<AuthenticationGuard component={Knowledgebase} />} />
                <Route path="/guide" element={<AuthenticationGuard component={Guide} />} />
                <Route path="/user-profile" element={<AuthenticationGuard component={UpdateUser} />} />
                <Route path="/sop" element={<AuthenticationGuard component={Sop} />} />
                {user.center === 'lead' && (
                    <>
                        <Route path="/impact-reports" element={<AuthenticationGuard component={PartnerReports} />} />
                        <Route path="/ai" element={<AuthenticationGuard component={AI} />} />
                        <Route path="/generate-video" element={<AuthenticationGuard component={GenerateVideo} />} />
                    </>
                )}
                <Route path="/callback" element={<CallbackPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </>
    );
};