import axios from 'axios';

const API_URL = 'https://holy-renewal-e45d5ab32b.strapiapp.com/api'; // Replace with your Strapi API URL
// Fetch staff members with bearer token authentication
export const fetchStaffMembers = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/staff-members?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching staff members:', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};

// https://holy-renewal-e45d5ab32b.strapiapp.com/api/accounts?populate=*

export const fetchAccounts = async (centerName) => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/accounts`, {
            params: {
                'populate[UserAccount][populate][0]': 'center'
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        // Filter the accounts based on the center name
        const filteredAccounts = response.data.data.filter(account => {
            const accountCenterName = account.attributes.UserAccount.center.data.attributes.Name;
            return accountCenterName.toLowerCase() === centerName.toLowerCase();
        });

        return filteredAccounts;
    } catch (error) {
        console.error('Error fetching Accounts', error);
        throw error;
    }
};

export const fetchSuccessStories = async (centerName) => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/success-stories`, {
            params: {
                'populate': '*',
                'filters[center][Name][$eqi]': centerName
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        console.log(response.data, 'responseData');

        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching success stories:', error);
        throw error;
    }
};

export const fetchBranding = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/brandings?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching staff members:', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};

export const fetchHeadlines = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/headlines?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching Headlines', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};


export const fetchImpact = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/impact`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data.data);
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching impact', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};


export const fetchAgenda = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/agendas/1?populate[agenda_days][populate][agenda_events][populate]=presenter`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching agenda', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};      

