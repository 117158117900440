import React from 'react';
import { Box, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, Typography,Link as MuiLink, List, ListItem, ListItemText, Divider, CardHeader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'; // Outlined Icon for Solution
import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined'; // Outlined Icon for Recommendation
import { PageWrapper } from './PageWrapper';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'; // Import the default layout CSS

const AccreditationData = [
    {
        "title": "Accreditation Condition 2.2 – Customer Relationships",
        "condition": "The standard will be satisfied when the SBDC demonstrates how it analyzes timely and actionable information on customer satisfaction/dissatisfaction and engagement, to include trending and demonstrates learning to support continuous improvement.",
        "recommendations": [
            {
                "recommendation": "Leadership is encouraged to survey all clients, not just new clients, and Entrepreneur Academy participants to gather a broad view of satisfaction/dissatisfaction.",
                "solution": "The Lead Center developed and deployed a 'follow-on' survey that goes out to all clients who have received follow-on counseling. Questions concerning the satisfaction of the Entrepreneur Academy have been added to the initial and follow-on satisfaction surveys.",
                "link1": null,
                "linkLocation1": "internal",
                "buttonText1": "View Survey",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Entrepreneur Academy Details"
            },
            {
                "recommendation": "Leadership is encouraged to establish a more frequent survey tempo than biannually to gather timely client feedback that allows for appropriate response and action. Response rates may improve when surveys are sent in close proximity following an SBDC appointment.",
                "solution": "The client satisfaction survey for both initial and follow-on clients has been set to be sent the day following each recorded session in CIC. Since implementation, response rate has increased from 7% to 12% and overall satisfaction has increased in every category.",
                "link1": null,
                "linkLocation1": "internal",
                "buttonText1": "Client Feedback",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Satisfaction Metrics"
            },
            {
                "recommendation": "Leadership is encouraged to establish a systematic survey process that is automated, results are available to regional directors, timely to drive higher response rate, and trended to show areas for improvement.",
                "solution": "CenterIC does not have an API which prevents the automation of the survey process. However, the Lead center will export client satisfaction data on a monthly basis and upload the information to the Utah SBDC dashboard where the results will be available to all center directors. Trends in response and satisfaction rates will be discussed during monthly online 'huddles'.",
                "link1": null,
                "linkLocation1": "internal",
                "buttonText1": "Export Data Process",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "API Details"
            }
        ]
    },
    {
        "title": "Accreditation Condition 3.2 – Strategic Plan",
        "condition": "The condition will be met when the USBDC has a strategic plan that identifies key strategic priorities, objectives, metrics, and timetables.",
        "recommendations": [
            {
                "recommendation": "The team strongly encourages leadership to engage an outside facilitator to help them focus on key inspirational strategic elements that will move the network forward in a strategic manner. A facilitator can also support leadership with the logistics of strategic planning such as establishing measurable metrics and executable timetables, and follow-on meetings to refine the strategic plan.",
                "solution": "Before the Utah SBDC October Director's meeting, the network agreed that the State Director would lead a strategic planning session during the meeting. With extensive experience in facilitating similar sessions, the State Director was well-prepared to guide the process. The session included the State Director, Associate State Director, Director of Innovation and Technology, Regional Directors, and Associate Regional Directors. Together, they reviewed the existing strategic plan, evaluating its effectiveness and relevance for the future. The plan was refined to highlight the most critical strategic elements. Open dialogue was encouraged, ensuring that everyone had the opportunity to contribute. Through this collaborative process, the team reached a consensus and developed the following strategic plan (see plan).",
                "link1": "/strategic-plan",
                "linkLocation1": "internal",
                "buttonText1": "View Updated Strategic Plan",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Facilitator Information"
            },
            {
                "recommendation": "Leadership and the strategic planning teams could evaluate the elements/components that have been identified and determine which are truly the most significant and prioritize the focus on these as truly strategic priorities in order to limit the interference so as to better be able to manage valuable resources.",
                "solution": "See solution 1. response.",
                "link1": "/strategic-plan",
                "linkLocation1": "internal",
                "buttonText1": "View Updated Strategic Plan",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Strategic Resources"
            }
        ]
    },
    {
        "title": "Accreditation Condition 6.2 – Performance Analysis",
        "condition": "The standard will be satisfied when the SBDC demonstrates analysis of performance measures, including trending and benchmarking against other organizations, and demonstrates learning to support continuous improvement.",
        "recommendations": [
            {
                "recommendation": "Leadership is strongly encouraged to review performance data by client segments, demographic groups, industry categories, geographic areas, etc. to ensure alignment with strategic priorities and stakeholder needs, to assess ROI and inform decisions about operations.",
                "solution": "Using the Chrisman study as a guide, the Lead center has normalized center performance data based on funding levels and has performed a trend analysis identifying top-performing centers in each KPI. This analysis will be used going forward to determine best practices for statewide implementation. Network KPIs and trends have already been uploaded to strategic partner dashboards and will be updated quarterly. Strategic partner dashboards include data by client segments, demographic groups, industry categories, and geographic areas which ensures alignment with strategic priorities and stakeholder needs. Ongoing strategic partner meetings will include a review and update of this data.",
                "link1": null,
                "linkLocation1": "internal",
                "buttonText1": "View Trend Analysis",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Chrisman Study"
            },
            {
                "recommendation": "Leadership is strongly encouraged to expand benchmarking to inform decision making in areas that are important to the network and partners.",
                "solution": "Using the Chrisman Study, the Lead Center has analyzed other state network data across multiple categories to determine Utah’s ranking against other organizations. The trend analysis revealed the following statistics: Utah has shown consistent ranking improvement in every performance category over the past decade, especially in the last 3 years. Over the past 3 years, Utah’s average ranking has been 9th out of 31 states analyzed, putting the network solidly within the top 10 states which highlights Utah’s overall competitiveness and effectiveness. Over the past 3 years, Utah has ranked consistently in the top 6 states for Budget per Long-Term Client, emphasizing a strong focus on sustained client support.",
                "link1": null,
                "linkLocation1": "internal",
                "buttonText1": "Network Rankings",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Benchmark Details"
            },
            {
                "recommendation": "The team recommends efficiency and effectiveness ratios be analyzed and recommends normalizing the data on per capita basis or funding levels.",
                "solution": "Using the Chrisman Study as a guide, the Lead Center has analyzed center KPIs against funding and network-wide KPIs. Trended data will be available on each center’s dashboard and discussed during Lead Center biennial site reviews. As best practices are identified, they are discussed at online 'huddles'.",
                "link1": null,
                "linkLocation1": "internal",
                "buttonText1": "KPI Data",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Funding Analysis"
            }
        ]
    },
    {
        "title": "Accreditation Condition 6.3 – Data Integrity",
        "condition": "The standard will be satisfied when the SBDC has a systematic process to ensure data and information is accurate and impact is attributed by the client.",
        "recommendations": [
            {
                "recommendation": "Verify and validate all 2024 impact already collected for accuracy and attribution and make necessary corrections before the end of federal fiscal year NEXUS reporting.",
                "solution": "Each center reviewed all 2024 impact sessions and ensured appropriate counseling notes and client attribution. Deadline: September 20, 2024. Lead center then reviewed all 2024 network-wide impact sessions for attribution prior to federal fiscal year-end 9/30/2024. The three acceptable methods for client attribution are: email, CGA, or CIC survey.",
                "link1": null,
                "linkLocation1": "internal",
                "buttonText1": "Impact Review",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "NEXUS Guidelines"
            },
            {
                "recommendation": "Actively manage the process from the Lead Center with timely reviews of impact sessions that are more frequent than annually. Perhaps at least quarterly in preparation for NEXUS uploads.",
                "solution": "Center Directors will verify each impact session monthly for proof of impact and client attribution. The “verify” box in CenterIC will be marked by center directors. The lead center will check each impact session monthly for proof of impact.",
                "link1": null,
                "linkLocation1": "external",
                "buttonText1": "Client Attribution Policy",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Impact Attribution"
            },
            {
                "recommendation": "Review every impact session for validation, verification and attribution.",
                "solution": "Same as above",
                "link1": null,
                "linkLocation1": "external",
                "buttonText1": "Client Attribution Policy",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Staff Training Resources"
            },
            {
                "recommendation": "Create a standard Center IC impact survey with consistent attribution language and questions that all Regional Directors use.",
                "solution": "All CenterIC impact surveys were collected and reviewed for standardization. One standardized statewide CenterIC impact survey was created for each center to send out annually (See Impact Survey).",
                "link1": "https://utahsbdc.s3.us-west-2.amazonaws.com/documents/Utah+SBDC+Standardized+Impact+Survey.pdf",
                "linkLocation1": "external",
                "buttonText1": "View Impact Survey",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Attribution Policy"
            },
            {
                "recommendation": "Review the CGA form and impact surveys for clarity to ensure clients understand what is being requested that they report and attribute to the SBDC.",
                "solution": "When required by the attribution policy, CGA form will be filled out with the client so that counselors can assist clients with the form for accuracy (See Attribution Policy).",
                "link1": "https://utahsbdc.s3.us-west-2.amazonaws.com/documents/Utah+SBDC+Client+Attribution+Policy%5B44%5D.pdf",
                "linkLocation1": "external",
                "buttonText1": "Client Attribution Policy",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Staff Training Resources"
            },
            {
                "recommendation": "Review guidelines with staff for entering session notes to ensure everyone understands expectations for documenting their SBDC work with clients.",
                "solution": "Session notes are reviewed during Lead Center impact verification. Session notes that are not in compliance are returned to the director for revision. Also, during biennial site evaluations by the lead center, State and Associate State Director will verify that regional centers have reviewed guidelines with staff and are entering notes correctly. Session note requirements are also reviewed during monthly online 'huddles'.",
                "link1": null,
                "linkLocation1": "external",
                "buttonText1": "Client Attribution Policy",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Staff Training Resources"
            },
            {
                "recommendation": "Document processes for sessions notes and data verification, validation and attribution.",
                "solution": "Ongoing training regarding counseling session notes policy and client attribution policy will be provided by the lead center at director’s meetings and during monthly online 'huddles'.",
                "link1": null,
                "linkLocation1": "external",
                "buttonText1": "Client Attribution Policy",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Attribution Policy"
            },
            {
                "recommendation": "Provide frequent staff training on expectations and policies for session notes and data verification, validation and attribution.",
                "solution": "See item #6 above.",
                "link1": null,
                "linkLocation1": "external",
                "buttonText1": "Client Attribution Policy",
                "link2": null,
                "linkLocation2": "external",
                "buttonText2": "Attribution Policy"
            }
        ]
    }
];

const AccreditationReport = () => {
    return (
        <PageWrapper title={"Accreditation Report"}>
            <div>
                {AccreditationData.map((item, index) => (
                    <>
                        <Typography variant="h6" gutterBottom sx={{ fontSize: 18 }}>{item.title}</Typography>
                        <Divider />
                        <Typography variant="body1" sx={{ fontSize: 16, fontWeight: '300', fontStyle: 'italic', p: 2 }} gutterBottom>"{item.condition}"</Typography>
                        <List>
                            {item.recommendations.map((rec, idx) => (
                                <Box key={idx} sx={{ mb: 3, ml: 3 }}>
                                    <Typography sx={{ fontSize: 14, mb: 2, fontWeight: 'bold' }}>Recommendation {idx + 1}.0</Typography>
                                    <Card variant="outlined" sx={{ backgroundColor: "#fdfdfd", ml: 3 }} style={{ marginBottom: '10px' }}>
                                        <CardContent>
                                            <Box sx={{ backgroundColor: "#f7f7f7", p: 2, borderRadius: 1 }}>
                                                <Typography variant="h6" sx={{ mb: 1 }} color="primary">
                                                    <LightbulbOutlined sx={{ verticalAlign: 'middle', mr: 1 }} />
                                                    Recommendation:
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontSize: 14, fontStyle: 'italic' }}>{rec.recommendation}</Typography>
                                            </Box>
                                            <Box sx={{ backgroundColor: '#f4fbfc', p: 2, mt: 2, borderRadius: 1 }}>
                                                <Typography variant="h6" sx={{ mb: 1 }} color="primary">
                                                    <CheckCircleOutlined sx={{ verticalAlign: 'middle', mr: 1 }} />
                                                    Solution:
                                                </Typography>
                                                <Typography variant="body1" sx={{ fontSize: 14 }}>{rec.solution}</Typography>
                                            </Box>
                                            <Box sx={{ mt: 2 }}>
                                                {rec.link1 && (
                                                    <Button variant="outlined" color="primary" sx={{ mr: 1 }}>
                                                        {rec.linkLocation1 === "external" ? (
                                                            <MuiLink href={rec.link1} target="_blank" underline="none" color="inherit">
                                                                {rec.buttonText1}
                                                            </MuiLink>
                                                        ) : (
                                                            <RouterLink to={rec.link1} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                {rec.buttonText1}
                                                            </RouterLink>
                                                        )}
                                                    </Button>
                                                )}
                                                {rec.link2 && (
                                                    <Button variant="outlined" color="primary">
                                                        {rec.linkLocation2 === "external" ? (
                                                            <MuiLink href={rec.link2} target="_blank" underline="none" color="inherit">
                                                                {rec.buttonText2}
                                                            </MuiLink>
                                                        ) : (
                                                            <RouterLink to={rec.link2} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                                {rec.buttonText2}
                                                            </RouterLink>
                                                        )}
                                                    </Button>
                                                )}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            ))}
                        </List>
                    </>
                ))}
            </div>
        </PageWrapper>
    );
};
export default AccreditationReport;
