import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, CardContent, CardHeader, Divider, Typography, Box, Button } from "@mui/material";
import { PageWrapper } from "./PageWrapper";
import { fetchSuccessStories } from "../services/apiService";

export const SuccessStoriesPage = () => {
  const { user } = useAuth0();
  const [stories, setStories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSuccessStories = async () => {
      if (!user) {
        console.error('User is undefined');
        return;
      }
      if (!user.center) {
        console.error('User center is undefined');
        return;
      }
      try {
        const data = await fetchSuccessStories(user.center);
        setStories(data);
        console.log(data, 'success-stories', user.center);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getSuccessStories();
  }, [user]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading success stories: {error.message}</p>;

  return (
    <PageWrapper title={"Success Stories"} showSecondaryTitle={true}>
      <div className="content-layout">
        <Divider sx={{ my: 2 }} />
        {stories.length === 0 ? (
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                No success story has been submitted.
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Please have your client submit the intake form.
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                href="https://utahsbdc.org/client-success-story/" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Utah SBDC Success Story Submission
              </Button>
            </CardContent>
          </Card>
        ) : (
          stories.map(story => (
            <Card key={story.id} sx={{ mb: 3 }}>
              <CardHeader title={story.attributes.Year} sx={{ backgroundColor: '#efefef' }} />
              <CardContent>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' }, 
                    alignItems: { xs: 'center', md: 'flex-start' }, 
                    justifyContent: { xs: 'center', md: 'flex-start' } 
                  }}
                >
                  {story.attributes.logo && 
                   story.attributes.logo.data && 
                   story.attributes.logo.data.attributes && 
                   story.attributes.logo.data.attributes.url && (
                    <Box 
                      sx={{ 
                        width: 100, 
                        height: 100, 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        boxShadow: 1, 
                        marginRight: { md: 3 }, 
                        marginBottom: { xs: '10px', md: 0 }, 
                        overflow: 'hidden'
                      }}
                    >
                      <img 
                        src={story.attributes.logo.data.attributes.url} 
                        alt={story.attributes.logo.data.attributes.name} 
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography variant="h2" sx={{ fontSize: 18, mb: 1 }}>
                      {story.attributes.businessName}
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic', mb: 2 }}>
                      Status: {story.attributes.status}
                    </Typography>
                    <Typography variant="body1">
                      Contact: {story.attributes.clientName}
                    </Typography>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      sx={{ mt: 2 }}
                      onClick={() => window.open(story.attributes.submissionLink, '_blank')}
                    >
                      View Submission 
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))
        )}
      </div>
    </PageWrapper>
  );
};